import "./App.css";
import "react-loading-skeleton/dist/skeleton.css";

import NavBar from "./components/NavBars/NavBar";
import DetailsVideo from "./components/Details/DetailsVideo";
import FAQ from "./components/FAQ/FAQ";
import TOS from "./components/TOS/TOS";

import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useMyContext } from "./contexts/StateHolder";
import BottomBar from "./components/Footers/BottomBar";
import Profile from "./components/Profile/Profile";
import settings from "./configs/config_settings.json";
import Banner from "./components/ViewAssets/Sliders/Banner";
import Search from "./components/Search/Search";
import { useTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import CategoriesWithTitles from "./components/ViewAssets/Sliders/CategoriesWithTitles";

import { getCategories } from "./scripts/dataHandlers";
import NavBarButton from "./components/NavBars/NavBarButton";
import AssetVideoPlayer from "./components/VideoPlayer/AssetVideoPlayer";
import SignUpForm from "./components/SignUp/SignUpForm";
import LoginForm from "./components/Login/LoginForm";
import { useCookies } from "react-cookie";
import "./Shared/FontAwesomeIcon";
import ListAllCategories from "./components/Categories/ListAllCategories";
import LiveVideoPlayer from "./components/VideoPlayer/LiveVideoPlayer";
import ChannelOne from "./components/LiveChannel/ChannelOne";
import * as classes from "./components/NavBars/NavBar.module.css";
import ContactUs from "./components/ContactUs/ContactUs";

import DetailsSeasonsAndEpisodes from "./components/Details/DetailsSeasonsAndEpisodes";
// new slick

import "react-multi-carousel/lib/styles.css";

// React spinner
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// toastify
import "react-toastify/dist/ReactToastify.css";

// Google Analytics
import ReactGA from "react-ga";

import TrendingTopShows from "./components/ViewAssets/Sliders/Series/TrendingTopShows/TrendingTopShows";

import SeriesAsset from "./components/ViewAssets/Sliders/Series/SeriesAsset/SeriesAsset";
import LiveChannels from "./components/ViewAssets/Sliders/Series/LiveChannels/LiveChannels";
// import { createToken } from "./scripts/utils";
import CookiesPolicy from "./components/CookiesPolicy/CookiesPolicy";
import TVGuide from "./components/TVGuide/TVGuide";
import ReactVideoPlayer from "./components/VideoPlayer/ReactVideoPlayer";
import AddPoster from "./components/AddPoster/AddPoster";
import MoviesList from "./components/Movie/MoviesList";
import Allshows from "./components/AllShows/AllShows";
import AllShowsListingPage from "./components/AllShowsListingPage/AllShowsListingPage";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import ListAllRootCategories from "./components/ViewAssets/ListAllRootCategories/ListAllRootCategories";
import SingleCateoryAssets from "./components/ViewAssets/ListAllRootCategories/SingleCateoryAssets";

import RequestResetPassword from "./pages/RequestResetPassword.js";

if (settings.googleAnalytics !== "") {
  // Initialize Google analytics with API-key from .env file
  ReactGA.initialize(settings.googleAnalytics);
}

function App() {
  // Bring stateholders from context
  const {
    setAllCategories,

    key,
    setKey,

    organizationId,
    setOrganizationId,

    language,
    setLanguage,

    user,
    setUser,

    setAllLanguages,
    userLoggedIn,
  } = useMyContext();

  const location = useLocation();

  const { t } = useTranslation();

  const [cookies] = useCookies("");
  const [loading, setLoading] = useState(true);
  const [seasonCount, setSeasonCount] = useState();
  const [episodeNumber, setEpisodeNumber] = useState();
  const [authLoaded, setAuthLoaded] = useState(false);

  // UseEffect that will re-trigger on every pagechange, sending current location to Google Analytics
  useEffect(() => {
    if (settings.googleAnalytics !== "") {
      // Report Google Analytics about user entering this page
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [location]);

  /**** STEP 1, FIRST TIME INIT PROGRAM ****/
  useEffect(() => {
    async function initProgram() {
      try {
        // Set organizationId to context
        setOrganizationId(settings.organization.organizationId);

        // Set key to context
        setKey(settings.organization.key);

        // Set language from config and set it to context
        setLanguage(settings.language);

        setAllLanguages(
          Object.keys(settings.languages).map((k) => settings.languages[k])
        );
      } catch (err) {
        console.log(err);
      }
    }

    initProgram();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**** STEP 2, CHECK LANGUAGE AND GET CATEGORIES (STEP WILL BE REPEATED IF LANGUAGE CHANGES) ****/
  useEffect(() => {
    async function getAndSetCategories() {
      try {
        // Get categories from datahandler
        const categoryList = await getCategories(
          organizationId,
          key,
          language,
          user
        );

        // console.log("All Category List=>", categoryList)

        // Set categories in context
        setAllCategories(categoryList);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }

    // Get categories only if language is set
    if (language !== "") {
      getAndSetCategories();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  useEffect(() => {
    if (cookies?.userData?.userToken || userLoggedIn) {
      if (!user?.loggedIn && cookies?.userData?.userToken) {
        console.log(user);
        let modifiedUser = { ...user };

        // we will fill data from cookie if it is not there

        modifiedUser.loggedIn = true;

        modifiedUser.userId = cookies?.userData?.userId;

        modifiedUser.userToken = cookies?.userData?.userToken;

        modifiedUser.firstName = cookies?.userData?.firstName;

        modifiedUser.organizationId = cookies?.userData?.userOrganizationId;
        modifiedUser.eMail = cookies?.ue;
        modifiedUser.organizationName = cookies?.userData?.userOrganizationName;

        setUser(modifiedUser);
      }

      // getiing package of parent org ->- we need to match these later to check the event packages

      setTimeout(() => {
        setAuthLoaded(true);
      }, 100);
    } else {
      setAuthLoaded(true);
    }
  }, [cookies?.userData?.userToken, userLoggedIn, user?.loggedIn]);

  return !loading && authLoaded ? (
    <div className="App">
      <div className="container">
        <Switch>
          <Route>
            <NavBar
              links={
                <>
                  <div className={classes.navBarRow_primary}>
                    <NavBarButton
                      route={settings.routes.liveChannels}
                      name={t("navBar.liveChannels")}
                      exact={false}
                      disableLinkFromOwnpage={true}
                    />
                    <NavBarButton
                      route={settings.routes.tvGuide}
                      name={t("navBar.tvGuide")}
                    />

                    <NavBarButton
                      route={settings.routes.tvShows}
                      name={t("navBar.tvShows")}
                    />
                  </div>
                  <div className={classes.navRight}>
                    {/* // show only in bigger screen */}
                    <NavBarButton
                      route={settings.routes.search}
                      icon="FaSearch"
                    />
                  </div>
                </>
              }
            />

            {/* ROUTE FOR FRONT PAGE */}
            <Route path="/" exact>
              <div className="homeContainer">
                {/* banner */}
                <Banner settings={settings.components.frontPageHeroBanner_01} />
                <div className="liveChannels liveOn">
                  {/* all live channels list */}
                  <LiveChannels
                    settings={settings.components.homepageLiveChannels}
                    showDuration={false}
                    showReleaseYear={false}
                    showPublishedDate={true}
                    hideDescription={true}
                    latestData={true}
                    showTitle={false}
                    titleName={t("seriesSlider.onnow")}
                    showOnNowDetails={true}
                  />
                </div>
                <div className="hero-container">
                  {/* advert post */}
                  <AddPoster />
                  {/* all videos */}
                  {/* <CategoriesWithTitles
                                        settings={settings.components.homepageListAllRootCategories}
                                        showDuration={false}
                                        showReleaseYear={false}
                                        showPublishedDate={true}
                                        hideDescription={true}
                                    /> */}
                  <ListAllRootCategories
                    settings={settings.components.homepageListAllRootCategories}
                    showDuration={false}
                    showReleaseYear={false}
                    showPublishedDate={true}
                    hideDescription={true}
                  />
                  {/* all top shows */}
                  <TrendingTopShows
                    settings={settings.components.homepageListTopShows}
                    titleName={t("seriesSlider.trendingShows")}
                    hideDescription={true}
                    showCategoryName={true}
                    showTitle={true}
                    showSerieViewAll={false}
                    showBanner={false}
                  />
                </div>
              </div>
            </Route>

            <Route path={`/${settings.routes.tvShows}`} exact>
              <Banner
                settings={settings.components.allShowsPageHeroBanner_01}
              />
              <MoviesList
                settings={settings.components.frontPageHeroBanner_01}
                imageType="coverImage"
              />
              <div className="MoviesSlider">
                {/* all top shows */}

                <TrendingTopShows
                  settings={settings.components.homepageListAllSeries_01}
                  titleName={"Trending Shows"}
                  hideDescription={true}
                  showCategoryName={true}
                  showTitle={true}
                  showSerieViewAll={false}
                  showBanner={false}
                />
                {/* recommended video category  */}
                <CategoriesWithTitles
                  settings={settings.components.recommended}
                  showDuration={false}
                  showReleaseYear={false}
                  showPublishedDate={true}
                  hideDescription={true}
                />
                {/* all videos  */}
                {/* <CategoriesWithTitles
                                    settings={settings.components.allShowsListAllRootCategories}
                                    showDuration={false}
                                    showReleaseYear={false}
                                    showPublishedDate={true}
                                    hideDescription={true}
                                /> */}
                <ListAllRootCategories
                  settings={settings.components.allShowsListAllRootCategories}
                  showDuration={false}
                  showReleaseYear={false}
                  showPublishedDate={true}
                  hideDescription={true}
                />
              </div>
            </Route>

            {/* /* ROUTE FOR LIVE CHANNEL_current/ */}
            <Route
              path={`/${settings.routes.liveChannels}/:orgId?/:lcId?`}
              exact
            >
              <ChannelOne />
              <div className="onnow-livechannel">
                {/* Trending manual */}
                <CategoriesWithTitles
                  settings={settings.components.trendingManual}
                  showDuration={false}
                  showReleaseYear={false}
                  showPublishedDate={true}
                  hideDescription={true}
                />
              </div>
            </Route>

            {/* /* ROUTE FOR TV Guide/ */}
            <Route path={`/${settings.routes.tvGuide}`} exact>
              <div className="TvGuideSection">
                <TVGuide />
              </div>
            </Route>

            {/* // route when clicked "viewall" from trending videos */}

            <Route path={`/all-shows/Trending`} exact>
              <ListAllCategories
                settings={settings.components.ListAllCategories_01}
              />
            </Route>
            {/* // route when clicked "viewall" from other video categories */}

            <Route
              path={`/${settings.routes.tvShows}/categories/:title/:catID`}
              exact
            >
              <ListAllCategories
                settings={settings.components.ListAllCategories_01}
              />
            </Route>

            {/* need to check this below */}

            <Route path={`/${settings.routes.playSerie}/:seriesId`} exact>
              <AllShowsListingPage
                settings={settings.components.ListAllCategories_01}
              />
              <TrendingTopShows
                settings={settings.components.frontPageCategories_01}
                titleName={t("seriesSlider.trendingShows")}
                hideDescription={true}
                showCategoryName={true}
                showTitle={true}
                showSerieViewAll={false}
                showBanner={false}
              />
            </Route>

            {/* /* live video player/ */}
            <Route path={`/${settings.routes.onlineTV}`} exact>
              <LiveVideoPlayer />
            </Route>

            {/* ROUTE FOR PLAY VIDEO*/}
            <Route
              path={`/${settings.routes.playVideo}/:orgId/:asset/:categoriesId?`}
              exact
            >
              <AssetVideoPlayer backRoute={"/"} playerAutoStart={true} />

              <DetailsVideo
                hideBanner={true}
                hideInfoData={true}
                showPublishedDate={true}
                hideShare={true}
                isTVGuideItem={true}
              />

              <div className="playMovieSlider">
                <CategoriesWithTitles
                  settings={settings.components.listSimilarCategories}
                  showDuration={false}
                  showReleaseYear={false}
                  showPublishedDate={true}
                  hideDescription={true}
                  takeGroupItemIdfromUrl={true}
                />
                {/* Trending manual */}
                <CategoriesWithTitles
                  settings={settings.components.trendingManual}
                  showDuration={false}
                  showReleaseYear={false}
                  showPublishedDate={true}
                  hideDescription={true}
                  setEpisodeNumber={setEpisodeNumber}
                />
              </div>
            </Route>

            {/* ROUTE FOR PLAY SERIE*/}
            <Route
              path={`/${settings.routes.playSerie}/:orgId/:asset/:serieId?/:seasonId?`}
            >
              <AssetVideoPlayer
                backRoute={"hidden"}
                playerAutoStart={true}
                backButton={false}
                isSerie={true}
              />

              <DetailsVideo
                hideBanner={true}
                //hideShare={true}
                hideInfoData={true}
                showPublishedDate={true}
                seasonCount={seasonCount}
                episodeNumber={episodeNumber}
                isSerie={true}
              />
              <DetailsSeasonsAndEpisodes
                playVideoRoute={settings.routes.playSerie}
                setSeasonCount={setSeasonCount}
                setEpisodeNumber={setEpisodeNumber}
              />
              <TrendingTopShows
                settings={settings.components.homepageListAllSeries_01}
                titleName={t("seriesSlider.trendingShows")}
                hideDescription={true}
                isSerieCategory={true}
                showCategoryName={true}
                showTitle={true}
                showSerieViewAll={false}
                showBanner={false}
              />
            </Route>

            {/* /* ROUTE FOR TV SHOWS/ */}

            {/* Route for particular shows items */}
            {/* need to check this */}
            <Route path={`/tvShows/:showName/:showId`} exact>
              <Allshows
                settings={settings.components.episodesWithSerieTitles_02}
                showDuration={false}
                showReleaseYear={false}
                showPublishedDate={false}
                showTitle={true}
                setEpisodeNumber={setEpisodeNumber}
              />
            </Route>

            {/* ROUTE FOR SVOD SERIE CATEGORIES VIEW*/}
            {/* need to check this */}

            <Route
              path={`/${settings.routes.svodSeriesCategoriesRoute}/:orgId/:asset/:serieId?/:seasonId?`}
              exact
            >
              {/* //hideCta hides play button */}
              <DetailsVideo
                hideCta={true}
                hideInfoData={true}
                isSerieCategory={true}
                showPublishedDate={false}
              />
              <DetailsSeasonsAndEpisodes
                playVideoRoute={settings.routes.playSerie}
              />
            </Route>

            {/* ROUTE FOR CATHCUPVIDEO */}
            <Route path={`/${settings.routes.playCatchUpVideo}`} exact>
              <ReactVideoPlayer showDetails={true} />
              <DetailsVideo
                hideBanner={true}
                //hideShare={true}
                hideInfoData={true}
                showPublishedDate={true}
                isSerie={false}
                isCatchUpPlay={true}
              />
            </Route>

            {/* ROUTE FOR PROFILE VIEW*/}

            <Route path={`/${settings.routes.profile}`}>
              <Profile settings={settings.components.profile} />
            </Route>

            {/* ROUTE FOR FAQ VIEW*/}
            <Route path={`/${settings.routes.faq}`}>
              <FAQ />
            </Route>

            {/* ROUTE FOR TOS VIEW*/}
            <Route path={`/${settings.routes.tos}`}>
              <TOS />
            </Route>

            {/* ROUTE FOR COOKIES POLICY VIEW*/}
            <Route path={`/${settings.routes.cookiesPolicy}`}>
              <CookiesPolicy />
            </Route>

            {/* ROUTE FOR SEARCH VIEW*/}
            <Route path={`/${settings.routes.search}`}>
              <Search settings={settings.components.search_01} />
            </Route>

            {/* ROUTE FOR LOGIN VIEW*/}

            <Route path={`/${settings.routes.login}`}>
              <LoginForm routes={settings.routes} />
            </Route>

            {/* ROUTE FOR SIGNUP VIEW*/}

            <Route path={`/${settings.routes.signUp}`}>
              <SignUpForm
                routes={settings.routes}
                settings={settings.components.signUp}
              />
            </Route>

            <Route path={`/${settings.routes.contactus}`}>
              <ContactUs />
            </Route>
            <Route path={`/${settings.routes.privacyPolicy}`}>
              <PrivacyPolicy />
            </Route>
            <Route path={`/${settings.routes.requestResetPassword}/:token?`}>
              <div className="main-container" style={{ flex: 1 }}>
                <RequestResetPassword />
              </div>
            </Route>

            <BottomBar routes={settings.routes} />
            {/* <Route path="/ads.txt" component={AdsTxtPage} /> */}

            {/* Route for app-ads.txt */}
            {/* <Route path="/app-ads.txt" component={AppAdsTxtPage} /> */}
          </Route>
        </Switch>
      </div>
    </div>
  ) : (
    <div
      className="display-flex-center"
      style={{ minHeight: "100vh", background: "#12161d" }}
    >
      <Loader type="TailSpin" color="#dfdfdf" height={50} width={50} />
    </div>
  );
}

export default App;
